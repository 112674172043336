<template>
  <div class="intersector">
    <mds-loader
      v-if="hasMoreData"
      class="intersector__status--loading"
      size="small"
    />
    <span
      v-else
      class="intersector__status--empty"
      v-text="'No more data available'"
    />
  </div>
</template>

<script>
import MdsLoader from '@mds/loader';

export default {
  name: 'IntersectionObserver',
  components: {
    MdsLoader,
  },
  props: {
    hasMoreData: {
      type: Boolean,
      default: false,
    },
  },
  mounted () {
    const observer = new IntersectionObserver(([entry]) => (entry?.isIntersecting && this.hasMoreData && this.$emit('intersect')));
    observer.observe(this.$el);
    this.$once('hook:beforeDestroy', () => observer.disconnect());
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';

.intersector {
  &__status {
    &--empty {
      @include mds-body-text-m();
      display: block;
      text-align: center;
      margin-top: $mds-space-5-x;
      color: $mds-text-color-secondary;
    }
  }
}
</style>
