<template>
  <mds-col>
    <mds-loader v-if="isLoading" />
    <mds-empty-state
      v-else-if="!initialFetch"
      class="empty-state"
      title="You Don’t Have Any Data Yet"
      message="Please apply any filter first."
    >
      <template #mds-empty-state-icon>
        <img :src="require('@/assets/illustrations/happy-face.svg')">
      </template>
    </mds-empty-state>
    <mds-empty-state
      v-else-if="!hasData"
      class="empty-state"
      title="You Don’t Have Any Data Yet"
      message="Please add a record to the database"
    >
      <template #mds-empty-state-icon>
        <img :src="require('@/assets/illustrations/sad-face.svg')">
      </template>
    </mds-empty-state>
    <slot
      v-else
      :state="state"
    />
    <intersection-observer
      v-if="hasData && isPaged"
      :has-more-data="!isLastPage"
      @intersect="fetchData({ shouldReset: false })"
    />
  </mds-col>
</template>

<script>
import MdsLoader from '@mds/loader';
import MdsEmptyState from '@mds/empty-state';
import IntersectionObserver from '@/components/common/IntersectionObserver';
import { COMMON } from '@/constants/store.constant';

export default {
  name: 'StoreProvider',
  components: {
    MdsLoader,
    MdsEmptyState,
    IntersectionObserver,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    getter: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: null,
    },
    isPaged: {
      type: Boolean,
      default: false,
    },
    isNewEm: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isLoading: true,
    };
  },
  computed: {
    state () {
      return this.$store.getters[`${this.module}/${this.getter}`];
    },
    isLastPage () {
      return this.$store.getters[`${this.module}/${COMMON.IS_LAST_PAGE}`];
    },
    shouldRefresh () {
      return this.$store.getters[`${this.module}/${COMMON.SHOULD_REFRESH}`] ?? true;
    },
    initialFetch () {
      return this.$store.getters[`${this.module}/${COMMON.INITIAL_FETCH}`] ?? true;
    },
    hasData () {
      return !!this.state.length || !!Object.keys(this.state).length;
    },
  },
  watch: {
    shouldRefresh: {
      handler () {
        this.isLoading = this.shouldRefresh && true;
        this.shouldRefresh && this.fetchData({ shouldReset: true });
      },
      deep: true,
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.fetchData({ shouldReset: true });
    });
  },
  destroyed () {
    this.shouldRefresh && this.$store.commit(`${this.module}/${COMMON.RESET_STATE}`);
  },
  methods: {
    async fetchData ({ shouldReset }) {
      this.initialFetch && await this.$store.dispatch(`${this.module}/${this.action}`, { params: this.params, shouldReset });
      this.isLoading = false;
      this.$store.commit(`${this.module}/${COMMON.UPDATE_STATE}`, { name: 'shouldRefresh', value: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state {
  margin: $mds-space-7-x auto;
}
</style>
